import styled from 'styled-components';

export const Menu = styled.main`
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;

  .menu-title {
    margin-top: 25px;
    margin-bottom: 45px;
  }

  .menu-info {
    margin-top: -34px;
    margin-bottom: 60px;
    font-size: 1rem;
    font-weight: normal;
  }

  .menu-sizes {
    display: flex;
    justify-content: flex-end;
    /* position: sticky; */
    top: 5px;
    margin-top: -25px;

    p {
      background-color: #e2dc54;
      padding: 2px;
      color: #050504;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: 6px;
      width: 50px;
    }

    p {
      margin-left: 2px;
      margin-right: 2px;
    }
  }
`;

export const Item = styled.div`
  margin-bottom: 40px;

  @media (min-width: 400px) {
    border-left: 1px solid #e2dc54;
    padding-left: 16px;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
  }

  .item-title {
    flex-grow: 1;
    color: #e2dc54;

    span {
      background-color: #e2dc54;
      padding: 2px 4px;
      color: #050504;
      font-size: 0.875rem;
      border-radius: 6px;
      margin-left: 6px;
    }
  }

  .item-price-wrapper {
    display: flex;

    h4 {
      text-align: center;
      width: 54px;
      /* border: 1px solid #ccc; */
    }
  }

  .item-desc {
    color: #efefef;
    margin-top: 5px;
    font-size: 0.875rem;
  }
`;
